.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  color: white;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.footer-left {
  max-width: 40%;
}

.footer-title {
  color: cyan;
  font-size: 22px;
  font-weight: bold;
}

.footer-text {
  color: gray;
  margin-top: 10px;
  line-height: 1.5;
}

.contact-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: cyan;
  color: black;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  cursor: pointer;
}

.contact-button:hover {
  background-color: lightcyan;
}

/* Right Section */
.footer-right {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
 
  min-height: 200px; 
}

.footer-right-content {
  
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

/* Contact (Left Side) */
.footer-right-left {
  text-align: left;
}

/* Links (Right Side) */
.footer-right-right {
  text-align: right;
}

.footer-links {
  list-style: none;
  padding: 0;
}
.blue-border {
  background: linear-gradient(to bottom right, #000000, #002020);;
  padding: 20px;
  border: 2px solid blue;
  border-width: 2px 0 0 2px; /* Top and left border only */
  width: 70%;
  border-radius: 50px 0 0 0; /* Rounded top-left corner */
}


.footer-links div {
  margin: 5px 0;
  cursor: pointer;
  color: gray;
}


.footer-links li:hover {
  color: white;
}

/* Bottom Text */
.right-bottom {
  color: gray;
  font-size: 14px;
  text-align: right;
  /* Pushes to the bottom */
}

/* Responsive */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    text-align: center;
  }

  .footer-left {
    max-width: 100%;
  }

  .footer-right {
    align-items: center;
    width: 100%;
  }

  .footer-right-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-right-left,
  .footer-right-right {
    text-align: center;
  }

  .right-bottom {
    text-align: center;
  }
}
