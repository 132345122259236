/* Navbar styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.heading {
  font-size: 24px;
}

/* Menu Toggle Button */
.menu-icon {
  display: none; /* Hide by default */
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
}

/* Navigation Menu */
.go-to {
  display: flex; /* Default for desktop */
  flex-direction: row;
  gap: 15px;
}

.go-to div {
  cursor: pointer;
  padding: 10px;
  color: white;
}

/* Mobile view */
@media screen and (max-width: 768px) {
  .menu-icon {
    display: block; /* Show menu icon on small screens */
  }

  .go-to {
    display: none; /* Hide by default */
    flex-direction: column;
    position: absolute;
    top: 50px; /* Below navbar */
    right: 20px;
  
    padding: 10px;
    border-radius: 5px;
    width: 150px;
  }

  .go-to.open {
    display: flex; /* Show when menu is open */
  }
}
