
@font-face {
  font-family: 'OCR A Extended';
  src: url('../assets//font/OCRAEXT\ \(1\).TTF') format('truetype');
}

.contact-page {
  
position: relative;
  display: flex;
 
}

.contact-left,
.contact-right {
  flex: 1; /* Equal width for both halves */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}



.contact-right {
  position: relative;

  color: #fff;
}

.contact-form {
  width: 80%;
  max-width: 400px;

  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: cyan;
}

.contact-form label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  color: #fff;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: none;
  border-radius: 5px;
  background: #111;
  color: #fff;
  outline: none;
}

.contact-form textarea {
  height: 100px;
}

.file-upload {
  margin: 20px 0;
  color: cyan;
}

.file-upload a {
  color: cyan;
  text-decoration: none;
}

.contact-form button {
  width: 100%;
  padding: 15px;
  background: linear-gradient(90deg, #00f5ff, #00a5a5);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #000;
  transition: all 0.3s ease-in-out;
}

.contact-form button:hover {
  background: linear-gradient(90deg, #00a5a5, #00f5ff);
}

.contact-right {
  text-align: center;
}



.contact-right p {
  font-size: 1.5rem;
  line-height: 1.8;
  margin-top: 10px;
  padding: 0 20px;
}
.background-image3 {
  z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: contain;
    transform: translate(-50%, -50%);
  }
  /* Pop-up modal styling */
/* Pop-up modal styling */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures it appears above everything else */
}

.popup-content {
  background: #fff; /* White background for contrast */
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px; /* Prevents it from being too wide */
  width: 80%;
  color: #333; /* Dark text color for readability */
}

.popup-content p {
  font-size: 18px;
  color: #333; /* Ensure text is visible */
  margin-bottom: 20px;
}

.popup-content strong {
  color: #e74c3c; /* Highlight email in red */
}

.popup-content button {
  padding: 8px 16px;
  background: #e74c3c; /* Red button */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.3s;
}

.popup-content button:hover {
  background: #c0392b; /* Darker red on hover */
}


/* Responsive Design */
@media (max-width: 768px) {
  .contact-page {
    flex-direction: column;
    height: auto;
  }

  .contact-left,
  .contact-right {
    flex: none;
    width: 100%;
    padding: 20px;
  }

  .contact-form {
    margin-bottom: 20px;
  }
}
