@font-face {
  font-family: 'OCR A Extended';
  src: url('../src/assets/font/OCRAEXT (1).TTF') format('truetype');
}

body {
  font-family: 'OCR A Extended', sans-serif;
  color: #FFFF;
   
  overflow-x: hidden;
}

/* Ensure padding is included in height calculations */
* {
  box-sizing: border-box;
}

.page {
  font-family: 'OCR A Extended', sans-serif;
 
  background: linear-gradient(to bottom right, #000000, #002020);
  width: 100%;
}

/* Page container */
.page-section {
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  padding: 20px; /* Default padding */
  margin: auto; /* Center content horizontally */
}

/* Page fade-in effect */
.page-section.fade-in {
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  transform: translateY(0); /* Move to normal position */
}

.status-diamonds {
  position: fixed;
    right : 5%;
  top: 60%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line-container {
  top: 10%;
  position: absolute;
  width: 3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.connecting-line {
  width: 3px;
  background-color: cyan;
  transition: height 0.3s ease-in-out;
}

.diamond {
  width: 15px;
  height: 15px;
  background-color: gray;
  transform: rotate(45deg);
  margin: 20px 0;
  transition: background-color 0.3s ease-in-out;
}

.diamond.active {
  background-color: cyan;
}

body{
  background: linear-gradient(to bottom right, #000000, #002020);
}