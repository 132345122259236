
@font-face {
  font-family: 'OCR A Extended';
  src: url('../assets//font/OCRAEXT\ \(1\).TTF') format('truetype');
}
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #111;
  font-family: "OCR A Extended", sans-serif;
}

.title {
  color: cyan;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.subtitle {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.size-text {
  font-size: 1rem;
  color: white;
  margin-bottom: 10px;
}

.progress-bar-container {
  position: relative;
  width: 300px;
  height: 40px;
  background: linear-gradient(135deg, #222, #333);
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid cyan;
  margin: 10px 0;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, cyan, rgba(255, 255, 255, 0.7));
  transition: width 0.3s ease-in-out;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 1rem;
  color: white;
}

.corner-top-right, .corner-bottom-left {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid cyan;
}

.corner-top-right {
  top: -2px;
  right: -2px;
  border-left: none;
  border-bottom: none;
}

.corner-bottom-left {
  bottom: -2px;
  left: -2px;
  border-right: none;
  border-top: none;
}
